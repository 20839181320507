import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class TitleService {
    constructor(
        private titleService: Title,
        private translocoService: TranslocoService
    ) {
        // Subscribe to language changes
        this.translocoService.langChanges$.subscribe(() => {
            this.updateTitle();
        });
    }

    private updateTitle(): void {
        // Get translated title
        this.translocoService.selectTranslate('app.title').subscribe(title => {
            this.titleService.setTitle(title);
        });
    }

    public initializeTitle(): void {
        this.updateTitle();
    }
} 