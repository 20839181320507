import { localStorageSync } from 'ngrx-store-localstorage';
import { MetaReducer } from '@ngrx/store';

/**
 * LocalStorage Sync Configuration for NgRx Store
 */
export function localStorageMetaReducer(reducer: any): any {
  return (state: any, action: any) => {
    const newState = reducer(state, action);
    const storedState = localStorageSync({
      keys: ['auth', 'masjid', 'news', 'manageMasjidEvents'],
      rehydrate: true,
      storage: localStorage,
      removeOnUndefined: true,
      restoreDates: false // Ensure no Date objects are restored
    })(reducer)(newState, action);
    return storedState;
  };
}

/**
 * MetaReducers Array
 */
export const metaReducers: MetaReducer<any>[] = [localStorageMetaReducer];
