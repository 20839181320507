import { Inject, Injectable, NgModule } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Routes, TitleStrategy, RouterStateSnapshot, RouterModule } from '@angular/router'
import { AboutComponent } from 'src/app/components/about/about.component'
import { ContactComponent } from 'src/app/components/contact/contact.component'
import { HomeComponent } from 'src/app/components/home/home.component'
import { GlobalErrorComponent } from 'src/app/shared/components/global-error/global-error.component'
import { CustomLayoutComponent } from 'src/app/shared/components/layout/custom-layout/custom-layout.component'
import { PageNotFoundComponent } from 'src/app/shared/components/page-not-found/page-not-found.component'
import { ErrorPageGuard } from './shared/guards/errorPage.guard'

export const appRoutes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: '',
        component: CustomLayoutComponent,
        children: [
            { path: 'home', title: 'Home', component: HomeComponent },
            { path: 'about', title: 'About', component: AboutComponent },
            {
                path: 'news',
                loadChildren: () =>
                    import('./news/news-routes').then(
                        (m) => m.newsRoutes
                    ),
            },
            { path: 'contact', title: 'Contact', component: ContactComponent },
            {
                path: 'search',
                title: 'Search Masjids',
                loadChildren: () =>
                    import('./search-masjids/search-masjids.routes').then(
                        (m) => m.searchMasjidsRoutes
                    ),
            },
            {
                path: 'auth',
                loadChildren: () =>
                    import('./auth/auth.routes').then(
                        (m) => m.authRoutes
                    ),
            },
            {
                path: 'masjid',
                loadChildren: () =>
                    import('./masjids/masjid.routes').then(
                        (m) => m.masjidRoutes
                    ),
            },
            {
                path: 'masjid-events',
                loadChildren: () =>
                    import('./masjid-events/masjid-events-routes').then(
                        (m) => m.masjidEventsRoutes
                    ),
            },
            {
                path: 'error',
                title: 'Error was found',
                component: GlobalErrorComponent,
                canActivate: [ErrorPageGuard]
            },
            {
                path: '**',
                title: '404 Page Not Found',
                component: PageNotFoundComponent,
            },
        ],
    },
]

@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategy extends TitleStrategy {
    constructor(@Inject(Title) private readonly title: Title) {
        super()
    }

    override updateTitle(routerState: RouterStateSnapshot): void {
        const title = this.buildTitle(routerState)
        if (title === '404 Page Not Found') {
            return this.title.setTitle(title)
        }

        if (title !== undefined) {
            return this.title.setTitle(`Masjids Info - ${title}`)
        }

        this.title.setTitle('Masjids Info')
    }
}