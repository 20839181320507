import { Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { Observable, map, of, switchMap } from 'rxjs'
import { MasjidDataInterface } from 'src/app/masjids/interfaces/masjidData.interface'
import { NewsInterface } from 'src/app/news/interfaces/news.interface'
import { MasjidDocumentService } from 'src/app/shared/services/masjid.service'
import { NewsService } from 'src/app/shared/services/news.service'
import { ContactComponent } from '../contact/contact.component'
import { FileStorageService } from 'src/app/shared/services/file-storage.service'
import { environment } from 'src/environments/environment'
import { TranslocoModule } from '@ngneat/transloco'
import { MasjidEventService } from 'src/app/masjid-events/services/masjid-event.service'
import { MasjidEvent } from 'src/app/masjid-events/interfaces/masjid-event.interface'
import { SearchBoxComponent } from 'src/app/search-masjids/components/search-box/search-box.component'

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatCardModule,
        ContactComponent,
        TranslocoModule,
        SearchBoxComponent
    ],
})
export class HomeComponent implements OnInit {
    featuredMasjids$: Observable<MasjidDataInterface[]>
    latestNews$: Observable<NewsInterface[]>
    upcomingEvents$: Observable<MasjidEvent[]>
    hasNews$: Observable<boolean>
    hasMasjids$: Observable<boolean>
    hasEvents$: Observable<boolean>

    constructor(
        private masjidService: MasjidDocumentService,
        private newsService: NewsService,
        private fileService: FileStorageService,
        private eventService: MasjidEventService
    ) {
        // Get latest news
        this.latestNews$ = this.newsService.getLatestNews()
        this.hasNews$ = this.latestNews$.pipe(
            map(news => news && news.length > 0)
        )

        // Get featured masjids (limiting to 3 for now)
        this.featuredMasjids$ = this.masjidService.getMasjidList().pipe(
            map(masjids => masjids.masjids.slice(0, 3))
        )
        this.hasMasjids$ = this.featuredMasjids$.pipe(
            map(masjids => masjids && masjids.length > 0)
        )

        // Get upcoming events, if none available get recent completed events
        this.upcomingEvents$ = this.eventService.getUpcomingEvents().pipe(
            switchMap(upcomingEvents => {
                if (upcomingEvents && upcomingEvents.length > 0) {
                    return of(upcomingEvents.slice(0, 3))
                }
                return this.eventService.getRecentCompletedEvents().pipe(
                    map(completedEvents => completedEvents.slice(0, 3))
                )
            })
        )
        this.hasEvents$ = this.upcomingEvents$.pipe(
            map(events => events && events.length > 0)
        )
    }

    getMasjidImageSrc(imageId: string): string {
        return this.fileService.getFilePreviewUrl(
            environment.masjid_images_bucket,
            imageId
        )
    }

    getEventImageSrc(imageId: string): string {
        return this.fileService.getFilePreviewUrl(
            environment.masjid_events_bucket,
            imageId
        )
    }

    ngOnInit(): void {}
}
