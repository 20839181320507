import { bootstrapApplication } from '@angular/platform-browser';
import { translationProviders } from './app/transloco-root.module';

import { AppComponent } from './app/app.component';
import { appProviders } from './app/app.providers';
import { materialProviders } from './app/shared/providers/material.providers';
import { authProviders } from './app/auth/auth.providers';
import { masjidProviders } from './app/masjids/masjid.providers';
import { newsProviders } from './app/news/news.providers';
import { masjidEventsProviders } from './app/masjid-events/masjid-events.providers';
import { searchMasjidsProviders } from './app/search-masjids/search-masjids.providers';

bootstrapApplication(AppComponent, {
  providers: [
    // Core providers
    ...appProviders,
    ...translationProviders,
    ...materialProviders,

    // Feature providers
    ...authProviders,
    ...masjidProviders,
    ...newsProviders,
    ...masjidEventsProviders,
    ...searchMasjidsProviders
  ],
}).catch(err => console.error(err));
