import { EnvironmentProviders, Provider } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { masjidEventReducer, editMasjidEventReducer } from './store/reducers';
import { effects } from './store/effects';
import { masjidEventsRoutes } from './masjid-events-routes';
import { provideRouter } from '@angular/router';
import { manageMasjidEventsReducer } from './store/reducers/manage-masjid-events.reducer';
import { MasjidEventService } from './services/masjid-event.service';
import { FileStorageService } from '../shared/services/file-storage.service';
import { NotificationService } from '../shared/services/notification.service';

export const masjidEventsProviders: (Provider | EnvironmentProviders)[] = [
  provideRouter(masjidEventsRoutes),
  provideState('masjidEvents', masjidEventReducer),
  provideState('editMasjidEvent', editMasjidEventReducer),
  provideState('manageMasjidEvents', manageMasjidEventsReducer),
  provideEffects(effects),
  NotificationService,
  FileStorageService,
  MasjidEventService
]; 