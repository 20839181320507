import { Injectable, ErrorHandler, NgZone, Injector } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { GlobalError } from '../interfaces/global-error.interface'
import { LogService } from './log.service'
import { Store, select } from '@ngrx/store'
import { take } from 'rxjs'
import { userSelector } from 'src/app/auth/store/selectors/auth.selectors'
import Utils from './utils'
import { LogEntry, LogType } from '../interfaces/log.interface'
import { AppwriteException } from 'appwrite'
import { HttpErrorResponse } from '@angular/common/http'

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
    private store?: Store

    constructor(
        private router: Router,
        private ngZone: NgZone,
        private logService: LogService,
        private injector: Injector
    ) {}

    handleError(error: any): void {
        console.log('Global error handler', error)
        // Always log the error
        this.logErrorToApi(error);

        // If it's not an Error or AppwriteException, let the component handle it
        if (!(error instanceof Error) && !(error instanceof AppwriteException)) {
            return;
        }

        // For Appwrite exceptions that we handle gracefully, don't show error page
        if (error instanceof AppwriteException && this.isKnownAppwriteError(error)) {
            return;
        }

        // For HTTP errors that aren't server errors, don't show error page
        if (error instanceof HttpErrorResponse && !this.isInternalServerError(error)) {
            return;
        }

        // Only proceed with global error handling for unexpected errors
        if (!this.store) {
            this.store = this.injector.get(Store);
        }

        this.store
            .pipe(select(userSelector), take(1))
            .subscribe((user) => {
                const userId = user?.$id || null;
                const correlationId = Utils.generateCorrelationId(userId);

                const errorDetails: GlobalError = {
                    message: this.getErrorMessage(error),
                    statusCode: this.getStatusCode(error),
                    stackTrace: error instanceof Error ? error.stack || 'No stack trace available' : 'No stack trace available',
                    correlationId,
                };

                this.ngZone.run(() => {
                    this.router.navigate(['/error'], {
                        queryParams: errorDetails,
                    });
                });
            });
    }

    private isInternalServerError(error: any): boolean {
        const statusCode = this.getStatusCode(error);
        const internalErrorCodes = [500, 501, 503, 504];
        return internalErrorCodes.includes(statusCode);
    }

    private isKnownAppwriteError(error: AppwriteException): boolean {
        const knownCodes = [
            400, // Bad Request
            401, // Unauthorized
            403, // Forbidden
            404, // Not found
            409, // Conflict (e.g., duplicate email)
            429  // Too many requests
        ];
        return knownCodes.includes(error.code);
    }

    private getStatusCode(error: any): number {
        if (error instanceof HttpErrorResponse) {
            return error.status;
        }
        if (error instanceof AppwriteException) {
            return error.code;
        }
        return error.status || error.statusCode || 500;
    }

    private getErrorMessage(error: any): string {
        const statusCode = this.getStatusCode(error);
        const defaultMessage = 'An unexpected error occurred. Please try again later.';

        const statusMessages: { [key: number]: string } = {
            500: 'Internal Server Error - Our team has been notified.',
            501: 'This feature is not yet implemented.',
            503: 'Service temporarily unavailable. Please try again later.',
            504: 'Server timeout - Please try again later.'
        };

        return statusMessages[statusCode] || defaultMessage;
    }

    private logErrorToApi(error: any) {
        let message: string;
        let logType = LogType.Error;

        if (error instanceof AppwriteException) {
            message = Utils.formatAppWriteException(error);
        } else {
            message = Utils.handleErrorMessage(error);
        }

        const logEntry: LogEntry = {
            message,
            logType
        }

        this.logService.writeLogAsync(logEntry);
    }

    isValidErrorParams(route: ActivatedRouteSnapshot): boolean {
        const params = route.queryParams

        if (!params || Object.keys(params).length === 0) return false

        // Required parameters
        if (!params['message'] || typeof params['message'] !== 'string')
            return false
        if (!params['statusCode'] || isNaN(Number(params['statusCode'])))
            return false

        // Optional parameters
        if (params['stackTrace'] && typeof params['stackTrace'] !== 'string')
            return false
        if (
            params['correlationId'] &&
            typeof params['correlationId'] !== 'string'
        )
            return false

        return true
    }
}
