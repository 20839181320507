import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import {
    FormGroup,
    FormBuilder,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import {
    MatError,
    MatFormFieldModule,
    MatLabel,
} from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store, select } from '@ngrx/store'
import { Observable, catchError, map, of, tap, finalize } from 'rxjs'
import { User } from 'src/app/auth/interfaces'
import { selectAuthState } from 'src/app/auth/store/selectors/auth.selectors'
import { ContractData } from 'src/app/shared/interfaces/contact.data.interface'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import { ContactService } from 'src/app/shared/services/contact.service'
import { LogService } from 'src/app/shared/services/log.service'
import { NotificationService } from 'src/app/shared/services/notification.service'


@UntilDestroy()
@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    standalone: true,
    imports: [
        MatError,
        MatFormFieldModule,
        MatLabel,
        MatCardModule,
        ReactiveFormsModule,
        CommonModule,
        TranslocoModule,
        MatInputModule,
        MatButtonModule
    ],
})
export class ContactComponent implements OnInit {
    formData: FormGroup
    isSubmitting$: Observable<boolean> = of(false)
    userData: User | null = null
    successMessage: string = ''
    errorMessage: string = ''

    constructor(
        private builder: FormBuilder,
        private logger: LogService,
        private contactService: ContactService,
        private notification: NotificationService,
        private _store: Store
    ) {
        // Initialize empty form first
        this.formData = this.builder.group({
            name: ['', [Validators.required]],
            emailAddress: ['', [Validators.required, Validators.email]],
            message: ['', [Validators.required]],
        })
        
        this.logger.writeLogAsync({
            message: 'contact component started!',
            logType: LogType.Info,
        })
    }

    ngOnInit() {
        // Subscribe to auth state to pre-fill form if user is logged in
        this._store
            .pipe(
                select(selectAuthState),
                untilDestroyed(this)
            )
            .subscribe(auth => {
                if (auth?.currentUser) {
                    this.userData = auth.currentUser
                    // Update form with user data
                    this.formData.patchValue({
                        name: `${this.userData?.firstName || ''} ${this.userData?.lastName || ''}`.trim(),
                        emailAddress: this.userData?.email || ''
                    })
                }
            })
    }

    onSubmit(form: Event) {
        this.errorMessage = ''
        this.successMessage = ''

        if (this.formData.valid) {
            let data: ContractData
            if (this.userData !== null) {
                // For authenticated users
                data = {
                    name: `${this.userData.firstName!} ${this.userData.lastName!}`,
                    email: this.userData.email,
                    message: this.formData.value.message,
                    userId: this.userData.$id,
                    registered: true,
                }
            } else {
                // For non-authenticated users
                data = {
                    name: this.formData.value.name,
                    email: this.formData.value.emailAddress,
                    message: this.formData.value.message,
                    userId: null,
                    registered: false,
                }
            }

            // Set isSubmitting to true
            this.isSubmitting$ = of(true)

            this.contactService
                .saveUserContactMessage(data)
                .pipe(
                    tap((response) => {
                        if (response) {  // Check if response exists
                            this.logger.writeLogAsync({
                                logType: LogType.Debug,
                                message: 'Your message was sent to the masjids info team successfully!'
                            })
                            this.notification.notifySuccess(
                                'Your message was sent to the masjids info team successfully!'
                            )
                            this.formData.reset()
                            this.successMessage = 'Your message was sent to the masjids info team successfully!'
                        }
                    }),
                    catchError((error) => {
                        this.logger.writeLogAsync({
                            logType: LogType.Error,
                            message: `Error saving contact message: ${error.message}`
                        })
                        this.notification.notifyFailure(
                            'Error saving message, please try again after sometime.'
                        )
                        this.errorMessage = 'Error saving message, please try again after sometime.'
                        return of(null)
                    }),
                    finalize(() => {
                        this.isSubmitting$ = of(false)
                    })
                )
                .subscribe()
        } else {
            Object.values(this.formData.controls).forEach((control) => {
                if (!control.valid) {
                    control.markAsTouched()
                }
            })
        }
    }
}
