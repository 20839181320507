import { MasjidDataInterface } from '../../../masjids/interfaces/masjidData.interface';
import { MasjidEvent } from '../../interfaces/masjid-event.interface';

export interface ManageMasjidEventsState {
  adminMasjids: MasjidDataInterface[];
  masjidEvents: { [key: string]: MasjidEvent[] };
  loading: boolean;
  error: string | null;
}

export const initialManageMasjidEventsState: ManageMasjidEventsState = {
  adminMasjids: [],
  masjidEvents: {},
  loading: false,
  error: null
}; 