import { Action, createReducer, on } from '@ngrx/store'
import {
    EditMasjidStateInterface,
    initialEditMasjidState,
} from 'src/app/masjids/interfaces/masjid.state.interface'
import {
    resetFiles,
    setDeleteFiles,
    setIsSubmittingSave,
    setLessons,
    setMasjidData,
    setUploadFiles,
    updateExistingMasjidFailure,
    updateExistingMasjidSuccess,
} from '../actions/edit.masjid.actions'

const editMasjidReducer = createReducer<EditMasjidStateInterface>(
    initialEditMasjidState,
    on(
        updateExistingMasjidSuccess,
        (state, action): EditMasjidStateInterface => ({
            ...state,
            updated: action.updated,
            masjidData: action.masjidData,
            isSubmittingSave: action.isSubmittingSave,
            loading: false,
        })
    ),
    on(
        updateExistingMasjidFailure,
        (state, action): EditMasjidStateInterface => ({
            ...state,
            updated: action.updated,
            error: action.error,
            isSubmittingSave: action.isSubmittingSave,
            loading: false,
        })
    ),
    on(setIsSubmittingSave, (state, { save }) => ({
        ...state,
        isSubmittingSave: save,
        loading: false,
    })),
    on(setLessons, (state, { newLessons, updateLessons, deleteLessons }) => ({
        ...state,
        newLessons: newLessons,
        updateLessons: updateLessons,
        deleteLessons: deleteLessons,
        loading: false,
    })),
    on(setMasjidData, (state, { data }) => ({
        ...state,
        masjidData: { ...state.masjidData, ...data },
        loading: false,
    })),
    on(setDeleteFiles, (state, { files }) => ({
        ...state,
        deleteFiles: files,
        loading: false,
    })),
    on(setUploadFiles, (state, { files }) => ({
        ...state,
        uploadFiles: files,
        loading: false,
    })),
    on(resetFiles, (state) => ({
        ...state,
        deleteFiles: [],
        uploadFiles: [],
        loading: false,
    }))
)

export function reducerEditMasjid(
    state: EditMasjidStateInterface | undefined,
    action: Action
): EditMasjidStateInterface {
    return editMasjidReducer(state, action)
}
