import { createReducer, on } from '@ngrx/store';
import { ManageMasjidEventsActions } from '../actions/manage-masjid-events.actions';
import { ManageMasjidEventsState, initialManageMasjidEventsState } from '../states/manage-masjid-events.state';

export const manageMasjidEventsReducer = createReducer(
  initialManageMasjidEventsState,
  on(ManageMasjidEventsActions.loadAdminMasjids, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(ManageMasjidEventsActions.loadAdminMasjidsSuccess, (state, { masjids }) => ({
    ...state,
    adminMasjids: masjids,
    loading: false
  })),
  on(ManageMasjidEventsActions.loadAdminMasjidsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),

  on(ManageMasjidEventsActions.loadMasjidEvents, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(ManageMasjidEventsActions.loadMasjidEventsSuccess, (state, { masjidId, events }) => ({
    ...state,
    masjidEvents: {
      ...state.masjidEvents,
      [masjidId]: events
    },
    loading: false
  })),
  on(ManageMasjidEventsActions.loadMasjidEventsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),

  on(ManageMasjidEventsActions.loadAllMasjidEvents, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(ManageMasjidEventsActions.loadAllMasjidEventsSuccess, (state, { masjidEvents }) => ({
    ...state,
    masjidEvents,
    loading: false
  })),
  on(ManageMasjidEventsActions.loadAllMasjidEventsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),

  on(ManageMasjidEventsActions.deleteEvent, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(ManageMasjidEventsActions.deleteEventSuccess, (state, { masjidId, eventId }) => ({
    ...state,
    masjidEvents: {
      ...state.masjidEvents,
      [masjidId]: state.masjidEvents[masjidId]?.filter(event => event.id !== eventId) || []
    },
    loading: false
  })),
  on(ManageMasjidEventsActions.deleteEventFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),

  on(ManageMasjidEventsActions.clearState, () => initialManageMasjidEventsState)
); 