import { Action, createReducer, on } from '@ngrx/store'
import { AuthActions } from '../actions/auth.actions'
import { initialAuthState, AuthState } from '../../interfaces'

const authReducer = createReducer(
    initialAuthState,
    on(
        AuthActions.registerSuccess,
        (state, action): AuthState => ({
            ...state,
            currentUser: action.currentUser,
            isAuthenticated: true,
        })
    ),
    on(
        AuthActions.loginSuccess,
        (state, action): AuthState => ({
            ...state,
            currentUser: action.auth.currentUser,
            isAuthenticated: true,
            isSubmitting: false,
            backendError: null,
        })
    ),
    on(
        AuthActions.loginFailure,
        (state, action): AuthState => ({
            ...state,
            isSubmitting: false,
            backendError: action.error,
            isAuthenticated: false,
            currentUser: null,
        })
    ),
    on(
        AuthActions.login,
        (state): AuthState => ({
            ...state,
            isSubmitting: true,
            backendError: null,
        })
    ),
    on(
        AuthActions.refreshCurrentUser,
        (state, action): AuthState => ({
            ...state,
            currentUser: action.user,
            isAuthenticated: true,
        })
    ),
    on(
        AuthActions.refreshUser,
        (state, action): AuthState => ({
            ...state,
            currentUser: action.user,
            isAuthenticated: true,
        })
    ),
    on(
        AuthActions.refreshAuthState,
        (state, action): AuthState => ({
            ...state,
            ...action.auth,
            isAuthenticated: true,
        })
    ),
    on(
        AuthActions.authSuccess,
        (state, action): AuthState => ({
            ...state,
            ...action.authState,
            isAuthenticated: true,
        })
    ),
    on(
        AuthActions.logout,
        (): AuthState => ({
            ...initialAuthState
        })
    )
)

export function reducerAuth(
    state: AuthState | undefined,
    action: Action
) {
    return authReducer(state, action)
}
