import { Inject, Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { APPWRITE_SDK, AppwriteSdk } from 'src/app/shared/services/appwrite.provider';
import { MasjidSearchResult, SearchRequest, SearchResponse } from '../interfaces/search-masjids.interface';
import { Query } from 'appwrite';
import { environment } from 'src/environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MasjidDocumentService } from 'src/app/shared/services/masjid.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class SearchMasjidsService {
    constructor(
        @Inject(APPWRITE_SDK) private appwrite: AppwriteSdk,
        private masjidService: MasjidDocumentService
    ) {}

    searchMasjids(request: SearchRequest): Observable<SearchResponse> {
        console.log('Search request:', request); // Debug log
        const queries: string[] = [];

        // Add search query if provided - use name as primary search field
        if (request.query) {
            const searchQuery = request.query.toLowerCase().trim();
            // Primary search on name field
            queries.push(Query.search('name', searchQuery));
        }

        // Add filters
        if (request.filters) {
            if (request.filters.city) {
                // Use contains for partial matches on city
                queries.push(Query.contains('city', request.filters.city.toLowerCase()));
            }
            if (request.filters.country) {
                // Use contains for partial matches on country instead of exact match
                queries.push(Query.contains('country', request.filters.country.toLowerCase()));
            }
            if (request.filters.rating) {
                const ratingValue = Number(request.filters.rating);
                if (!isNaN(ratingValue) && ratingValue >= 0 && ratingValue <= 5) {
                    queries.push(Query.greaterThanEqual('rating', ratingValue));
                }
            }
            if (request.filters.facilities?.length) {
                // Use array contains for facilities
                request.filters.facilities.forEach(facility => {
                    queries.push(Query.contains('masjid_facilities', [facility.toLowerCase()]));
                });
            }
        }

        // Add pagination
        const limit = request.limit || 10;
        const offset = ((request.page || 1) - 1) * limit;
        queries.push(Query.limit(limit));
        queries.push(Query.offset(offset));

        // Add default sorting by name for consistency
        queries.push(Query.orderAsc('name'));

        console.log('Search queries:', queries); // Debug log

        return from(
            this.appwrite.databases.listDocuments(
                environment.main_database_id,
                environment.masjid_data_collection_id,
                queries
            )
        ).pipe(
            tap(response => console.log('Raw API response:', response)), // Debug log
            map(response => {
                const searchResponse = {
                    results: response.documents.map(doc => this.masjidService.convertDocumentToInterface(doc)) as MasjidSearchResult[],
                    total: response.total,
                    page: request.page || 1,
                    limit
                } as SearchResponse;
                console.log('Transformed search response:', searchResponse); // Debug log
                return searchResponse;
            }),
            catchError(error => {
                console.error('Error searching masjids:', error);
                throw error;
            }),
            untilDestroyed(this)
        );
    }
} 