import { SearchMasjidState } from '../../interfaces/search-masjids.interface';

export const initialSearchMasjidState: SearchMasjidState = {
    searchResults: [],
    isLoading: false,
    error: null,
    searchQuery: '',
    filters: {
        city: undefined,
        country: undefined,
        facilities: undefined,
        rating: undefined,
        distance: undefined
    }
}; 