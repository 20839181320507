import { provideRouter } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { authRoutes } from './auth.routes';
import { reducerAuth } from './store/reducers/auth.reducers';
import { provideState } from '@ngrx/store';
import { initialAuthState } from './interfaces';
import { profileReducer } from './store/reducers/profile.reducer';
import { ProfileEffects } from './store/effects/profile.effects';
import { provideEffects } from '@ngrx/effects';
import { initialProfileState } from './interfaces/state.interface';
import { AuthEffects } from './store/effects/auth.effects';
import { StorageService } from '../shared/services/storage.service';
import { AuthenticatedGuard } from '../shared/guards/authenticated.guard';
import { NotificationService } from '../shared/services/notification.service';

export const authProviders = [
  provideRouter(authRoutes), 
  provideState('auth', reducerAuth, { initialState: initialAuthState }),
  provideState('profile', profileReducer, { initialState: initialProfileState }),
  provideEffects(ProfileEffects, AuthEffects),
  AuthService,
  StorageService,
  NotificationService,
  AuthenticatedGuard
];
