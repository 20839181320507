import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, forkJoin } from 'rxjs';
import { map, mergeMap, catchError, switchMap, tap } from 'rxjs/operators';
import { ManageMasjidEventsActions } from '../actions/manage-masjid-events.actions';
import { MasjidEventService } from '../../services/masjid-event.service';
import { MasjidDocumentService } from 'src/app/shared/services/masjid.service';
import { MasjidDataInterface } from '../../../masjids/interfaces/masjidData.interface';
import { MasjidEvent } from '../../interfaces/masjid-event.interface';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Models } from 'appwrite';
import { AuthState } from '../../../auth/interfaces';
import { APPWRITE_SDK, AppwriteSdk } from 'src/app/shared/services/appwrite.provider';

interface EventResult {
  masjidId: string;
  events: MasjidEvent[];
}

@Injectable()
export class ManageMasjidEventsEffects {
  loadAdminMasjids$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageMasjidEventsActions.loadAdminMasjids),
      tap(() => console.log('Loading admin masjids...')),
      mergeMap(() =>
        this.authService.isAuthenticated().pipe(
          mergeMap((authState: AuthState) => {
            if (!authState.currentUser?.$id) {
              return of(ManageMasjidEventsActions.loadAdminMasjidsFailure({ error: 'No user found' }));
            }
            return this.masjidDocumentService.getMasjidsByAdminId(authState.currentUser.$id).pipe(
              tap(masjids => console.log('Loaded admin masjids:', masjids)),
              map((masjids: MasjidDataInterface[]) =>
                ManageMasjidEventsActions.loadAdminMasjidsSuccess({ masjids })
              ),
              catchError((error) => {
                console.error('Error loading admin masjids:', error);
                return of(ManageMasjidEventsActions.loadAdminMasjidsFailure({ error }));
              })
            );
          })
        )
      )
    )
  );

  loadMasjidEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageMasjidEventsActions.loadMasjidEvents),
      tap(({ masjidId }) => console.log('Loading events for masjid:', masjidId)),
      mergeMap(({ masjidId }) =>
        this.masjidEventService.getEventsByMasjidId(masjidId).pipe(
          tap(events => console.log('API Response - Events for masjid', masjidId, ':', events)),
          map((events: MasjidEvent[]) =>
            ManageMasjidEventsActions.loadMasjidEventsSuccess({ masjidId, events })
          ),
          catchError((error) => {
            console.error('Error loading masjid events:', error);
            return of(ManageMasjidEventsActions.loadMasjidEventsFailure({ error }));
          })
        )
      )
    )
  );

  loadAllMasjidEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageMasjidEventsActions.loadAllMasjidEvents),
      tap(({ masjidIds }) => console.log('Loading events for masjids:', masjidIds)),
      mergeMap(({ masjidIds }) =>
        forkJoin(
          masjidIds.map(masjidId =>
            this.masjidEventService.getEventsByMasjidId(masjidId).pipe(
              map((events: MasjidEvent[]) => ({ masjidId, events }))
            )
          )
        ).pipe(
          tap(results => console.log('API Response - All masjid events:', results)),
          map((results: EventResult[]) => {
            const masjidEvents: { [key: string]: MasjidEvent[] } = {};
            results.forEach(({ masjidId, events }) => {
              masjidEvents[masjidId] = events;
            });
            return ManageMasjidEventsActions.loadAllMasjidEventsSuccess({ masjidEvents });
          }),
          catchError((error) => {
            console.error('Error loading all masjid events:', error);
            return of(ManageMasjidEventsActions.loadAllMasjidEventsFailure({ error }));
          })
        )
      )
    )
  );

  deleteEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageMasjidEventsActions.deleteEvent),
      tap(({ masjidId, eventId }) => console.log('Deleting event:', eventId, 'from masjid:', masjidId)),
      mergeMap(({ masjidId, eventId }) =>
        this.masjidEventService.deleteEvent(eventId).pipe(
          tap(() => console.log('Successfully deleted event:', eventId)),
          map(() =>
            ManageMasjidEventsActions.deleteEventSuccess({ masjidId, eventId })
          ),
          catchError((error) => {
            console.error('Error deleting event:', error);
            return of(ManageMasjidEventsActions.deleteEventFailure({ error }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private masjidEventService: MasjidEventService,
    private masjidDocumentService: MasjidDocumentService,
    private authService: AuthService
  ) {}
} 