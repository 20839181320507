<div id="app">
  <mat-sidenav-container class="sidenav-container" [dir]="(direction$ | async) ?? 'ltr'">
    <!-- Sidebar -->
    <mat-sidenav
      #drawer
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false"
      (mouseover)="isHover = true"
      (mouseout)="isHover = false"
      [fixedInViewport]="true"
      [class]="
        !(isHandset$ | async) && !isHover
          ? 'sidenav sidenav-minimize'
          : 'sidenav'
      "
    >
      <mat-toolbar color="primary">{{ 'navbar.title' | transloco }}</mat-toolbar>
      <!-- Navigation List -->
      <mat-list
        role="navigation"
      >
        <mat-list-item routerLink="/home" routerLinkActive="isActive" (click)="closeSidenavOnMobile()">
          <mat-icon matListIcon>home</mat-icon>
          <span class="text">{{ 'navbar.home' | transloco }}</span>
        </mat-list-item>

        <mat-list-item routerLink="/masjid/list" routerLinkActive="isActive" (click)="closeSidenavOnMobile()">
          <mat-icon matListIcon>mosque</mat-icon>
          <span class="text">{{ 'navbar.masjids' | transloco }}</span>
        </mat-list-item>

        <mat-list-item routerLink="/news/list" routerLinkActive="isActive" (click)="closeSidenavOnMobile()">
          <mat-icon matListIcon>feed</mat-icon>
          <span class="text">{{ 'navbar.news' | transloco }}</span>
        </mat-list-item>

        <mat-list-item routerLink="/masjid-events/list" routerLinkActive="isActive" (click)="closeSidenavOnMobile()">
          <mat-icon matListIcon>event</mat-icon>
          <span class="text">{{ 'navbar.events' | transloco }}</span>
        </mat-list-item>

        <mat-list-item routerLink="/about" routerLinkActive="isActive" (click)="closeSidenavOnMobile()">
          <mat-icon matListIcon>diversity_3</mat-icon>
          <span class="text">{{ 'navbar.about' | transloco }}</span>
        </mat-list-item>

        <mat-list-item routerLink="/contact" routerLinkActive="isActive" (click)="closeSidenavOnMobile()">
          <mat-icon matListIcon>call</mat-icon>
          <span class="text">{{ 'navbar.contact' | transloco }}</span>
        </mat-list-item>

        <!-- Auth Items -->
        <mat-list-item *ngIf="!(authState$ | async)?.isAuthenticated" routerLink="/auth/login" (click)="closeSidenavOnMobile()">
          <mat-icon matListIcon>login</mat-icon>
          <span class="text">{{ 'navbar.login' | transloco }}</span>
        </mat-list-item>

        <mat-list-item *ngIf="!(authState$ | async)?.isAuthenticated" routerLink="/auth/register" (click)="closeSidenavOnMobile()">
          <mat-icon matListIcon>create</mat-icon>
          <span class="text">{{ 'navbar.register' | transloco }}</span>
        </mat-list-item>
      </mat-list>

      <!-- Admin Section -->
      <mat-divider *ngIf="(authState$ | async)?.currentUser?.isAdmin"></mat-divider>
      <mat-list *ngIf="(authState$ | async)?.currentUser?.isAdmin" role="navigation">
        <mat-list-item routerLink="/masjid/manage-masjids" routerLinkActive="isActive" (click)="closeSidenavOnMobile()">
          <mat-icon matListIcon>admin_panel_settings</mat-icon>
          <span class="text">{{ 'navbar.manage_masjids' | transloco }}</span>
        </mat-list-item>
        <mat-list-item routerLink="/news/manage" routerLinkActive="isActive" (click)="closeSidenavOnMobile()">
          <mat-icon matListIcon>feed</mat-icon>
          <span class="text">{{ 'navbar.manage_news' | transloco }}</span>
        </mat-list-item>
        <mat-list-item routerLink="/masjid/create" routerLinkActive="isActive" (click)="closeSidenavOnMobile()">
          <mat-icon matListIcon>add_circle</mat-icon>
          <span class="text">{{ 'navbar.create_masjid' | transloco }}</span>
        </mat-list-item>
        <mat-list-item routerLink="/masjid-events/manage-events" routerLinkActive="isActive" (click)="closeSidenavOnMobile()">
          <mat-icon matListIcon>event</mat-icon>
          <span class="text">{{ 'navbar.manage_events' | transloco }}</span>
        </mat-list-item>
      </mat-list>
    </mat-sidenav>

    <!-- Main Content -->
    <mat-sidenav-content class="main-content background-light-image">
      <app-header
        (toggleSideNavEvent)="drawer.toggle()"
        (direction)="setDirection($event)"
      ></app-header>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
