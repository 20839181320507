import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Component, ViewChild, OnInit, ChangeDetectorRef, NgZone, ApplicationRef, ChangeDetectionStrategy } from '@angular/core'
import { map, shareReplay, delay, take, filter } from 'rxjs/operators'
import { firstValueFrom, BehaviorSubject } from 'rxjs'
import { Direction } from '@angular/cdk/bidi'
import { Store } from '@ngrx/store'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import { LogService } from 'src/app/shared/services/log.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MatSidenavModule, MatSidenav } from '@angular/material/sidenav'
import { CommonModule } from '@angular/common'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { HeaderComponent } from '../../header/header.component'
import { FooterComponent } from '../../footer/footer.component'
import { RouterModule, Router, NavigationEnd } from '@angular/router'
import { MatFormFieldModule } from '@angular/material/form-field'
import { ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { selectAuthState } from 'src/app/auth/store/selectors/auth.selectors'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@UntilDestroy()
@Component({
    selector: 'app-custom-layout',
    templateUrl: './custom-layout.component.html',
    styleUrls: ['./custom-layout.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ReactiveFormsModule,
        MatSidenavModule,
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatListModule,
        TranslocoModule,
        HeaderComponent,
        FooterComponent,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule
    ],
})
export class CustomLayoutComponent implements OnInit {
    @ViewChild('drawer') drawer!: MatSidenav

    private directionSubject = new BehaviorSubject<Direction>('ltr');
    public direction$ = this.directionSubject.asObservable().pipe(
        map(direction => direction as Direction | 'auto')
    );
    private isDirectionUpdatePending = false;
    public isHover = false;
    
    isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
        map(result => result.matches),
        shareReplay(1)
    )
    authState$ = this.store.select(selectAuthState)

    constructor(
        private breakpointObserver: BreakpointObserver,
        private store: Store,
        private _loggerService: LogService,
        private _translocoService: TranslocoService,
        private _cdr: ChangeDetectorRef,
        private _ngZone: NgZone,
        private _appRef: ApplicationRef,
        private _router: Router
    ) {
        // Set initial direction based on stored language
        const storedLang = localStorage.getItem('lang') || 'en';
        const initialDirection = storedLang === 'ar' ? 'rtl' : 'ltr';
        this.directionSubject.next(initialDirection);
        console.log('[Layout] Constructor - Initial direction:', initialDirection, 'from stored language:', storedLang);
    }

    private async updateDirection(newDirection: Direction, source: string) {
        if (this.isDirectionUpdatePending) {
            console.log(`[Layout] Direction update skipped - Update already pending (${source})`);
            return;
        }

        console.log(`[Layout] Updating direction - From: ${this.directionSubject.value} To: ${newDirection} (Source: ${source})`);
        this.isDirectionUpdatePending = true;

        this._ngZone.run(() => {
            this.directionSubject.next(newDirection);
            this._cdr.markForCheck();
            
            Promise.resolve().then(() => {
                this.isDirectionUpdatePending = false;
                console.log(`[Layout] Direction change complete - Current: ${this.directionSubject.value} (Source: ${source})`);
            });
        });
    }

    private setupLanguageChangeHandler() {
        console.log('[Layout] Setting up language change handler');
        this._translocoService.langChanges$
            .pipe(
                untilDestroyed(this),
                delay(50) // Small delay to ensure route transitions complete
            )
            .subscribe(async lang => {
                console.log('[Layout] Language changed to:', lang);
                const newDirection = lang === 'ar' ? 'rtl' : 'ltr';
                await this.updateDirection(newDirection, 'language-change');
            });
    }

    private setupRouteChangeHandler() {
        console.log('[Layout] Setting up route change handler');
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            untilDestroyed(this)
        ).subscribe(async (event: any) => {
            console.log('[Layout] Route changed to:', event.url);
            const currentLang = this._translocoService.getActiveLang();
            const newDirection = currentLang === 'ar' ? 'rtl' : 'ltr';
            await this.updateDirection(newDirection, 'route-change');
        });
    }

    ngOnInit() {
        console.log('[Layout] Initializing component');
        this.setupRouteChangeHandler();
        this.setupLanguageChangeHandler();

        this._loggerService.writeLogAsync({
            message: 'CustomLayoutComponent initialized',
            logType: LogType.Debug,
        });
    }

    async setDirection(direction: Direction) {
        console.log('[Layout] Manual direction change requested:', direction);
        if (this.directionSubject.value !== direction) {
            await this.updateDirection(direction, 'manual-change');
        }
    }

    closeSidenavOnMobile() {
        this.isHandset$.pipe(
            take(1),
            untilDestroyed(this)
        ).subscribe(isHandset => {
            if (isHandset) {
                this._ngZone.run(() => {
                    console.log('[Layout] Closing sidenav on mobile');
                    this.drawer.close();
                });
            }
        });
    }
}
