<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      *ngIf="isHandset$ | async"
      (click)="toggleSideNav()"
      class="menu-button"
    >
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>

    <div class="logo-container">
      <img
        class="logo"
        src="assets/images/logo/MasjidsInfoBlack.svg"
        alt="Masjids info logo"
      />
    </div>
    
    <div class="spacer"></div>

    <!-- Desktop Header Actions -->
    <div class="header-actions" *ngIf="!(isHandset$ | async)">
      <ng-container *ngIf="authState$ | async as authState">
        <button
          *ngIf="authState.isAuthenticated"
          routerLink="/auth/profile"
          mat-icon-button
          aria-label="Profile"
          class="action-button"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="account icon"
            fontIcon="account_circle"
            color="accent"
          ></mat-icon>
        </button>
        <button
          *ngIf="authState.isAuthenticated"
          (click)="logout()"
          mat-icon-button
          class="action-button"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="logout icon"
            fontIcon="logout"
            color="accent"
          ></mat-icon>
        </button>
      </ng-container>

      <mat-slide-toggle
        (change)="switchMode($event)"
        color="accent"
        class="theme-toggle"
      ></mat-slide-toggle>

      <button
        mat-icon-button
        [matMenuTriggerFor]="language"
        aria-label="Language icon-button with a menu"
        class="action-button"
      >
        <mat-icon color="accent">language</mat-icon>
      </button>
    </div>

    <!-- Mobile Menu Button -->
    <button
      *ngIf="isHandset$ | async"
      mat-icon-button
      (click)="toggleMobileMenu()"
      class="mobile-menu-button"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<!-- Mobile Menu -->
<div class="mobile-menu" [class.mobile-menu-open]="isMobileMenuOpen" *transloco="let t">
  <div class="mobile-menu-header">
    <span>{{ t('navbar.mobilemenu.title') }}</span>
  </div>
  <div class="mobile-menu-content">
    <ng-container *ngIf="authState$ | async as authState">
      <button
        *ngIf="authState.isAuthenticated"
        routerLink="/auth/profile"
        mat-button
        class="mobile-menu-item"
        (click)="toggleMobileMenu()"
      >
        <mat-icon color="accent">account_circle</mat-icon>
        <span>{{ t('navbar.mobilemenu.profile') }}</span>
      </button>
      <button
        *ngIf="authState.isAuthenticated"
        mat-button
        class="mobile-menu-item"
        (click)="logout(); toggleMobileMenu()"
      >
        <mat-icon color="accent">logout</mat-icon>
        <span>{{ t('navbar.mobilemenu.logout') }}</span>
      </button>
    </ng-container>

    <div class="mobile-menu-item theme-toggle-container">
      <span>{{ t('navbar.mobilemenu.darkMode') }}</span>
      <mat-slide-toggle
        (change)="switchMode($event)"
        color="accent"
        class="theme-toggle"
      ></mat-slide-toggle>
    </div>

    <button
      mat-button
      [matMenuTriggerFor]="language"
      class="mobile-menu-item"
    >
      <mat-icon color="accent">language</mat-icon>
      <span>{{ t('navbar.mobilemenu.language') }}</span>
    </button>
  </div>
</div>

<!-- Language Menu -->
<mat-menu #language="matMenu">
  <button
    mat-menu-item
    *ngFor="let language of languages"
    (click)="languagePicked(language.code)"
  >
    <span class="language-option">
      <img
        src="https://flagcdn.com/{{ language.country.toLowerCase() }}.svg"
        width="24"
        alt="{{ language.nativeName }}"
      />
      {{ language.nativeName }}
    </span>
  </button>
</mat-menu>

<!-- Backdrop for mobile menu -->
<div 
  class="mobile-menu-backdrop" 
  [class.mobile-menu-backdrop-visible]="isMobileMenuOpen"
  (click)="toggleMobileMenu()"
></div>
