import { Component, OnInit } from '@angular/core'
import { LogType } from './shared/interfaces/log.interface'
import { LogService } from './shared/services/log.service'
import { RouterModule } from '@angular/router'
import { NgxSpinnerModule } from 'ngx-spinner'
import { Store } from '@ngrx/store'
import { TitleService } from './shared/services/title.service'
import { AuthActions } from './auth/store/actions/auth.actions'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterModule, NgxSpinnerModule]
})
export class AppComponent implements OnInit {
    constructor(private logger: LogService, private store: Store, private titleService: TitleService) {
        logger.writeLogAsync({
            message: 'Starting masjids ui app!',
            logType: LogType.Debug,
        })
    }

    ngOnInit() {
        this.store.dispatch(AuthActions.checkAuth());
        this.titleService.initializeTitle();
    }
}
