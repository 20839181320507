import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { MasjidEventActions } from '../actions/masjid-event.actions';
import { MasjidEventService } from '../../services/masjid-event.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class MasjidEventEffects {
  loadEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MasjidEventActions.loadEvents),
      mergeMap(() =>
        this.masjidEventService.getEvents().pipe(
          map((events) => MasjidEventActions.loadEventsSuccess({ events })),
          catchError((error) =>
            of(MasjidEventActions.loadEventsFailure({ error: error.message }))
          )
        )
      )
    )
  );

  loadEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MasjidEventActions.loadEvent),
      mergeMap(({ id }) =>
        this.masjidEventService.getEvent(id).pipe(
          map((event) => MasjidEventActions.loadEventSuccess({ event })),
          catchError((error) =>
            of(MasjidEventActions.loadEventFailure({ error: error.message }))
          )
        )
      )
    )
  );

  createEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MasjidEventActions.createEvent),
      mergeMap(({ event }) =>
        this.masjidEventService.createEvent(event).pipe(
          map((createdEvent) =>
            MasjidEventActions.createEventSuccess({ event: createdEvent })
          ),
          tap(() => {
            this._notification.notifySuccess(
              this._translateService.translate('masjid_events.create.eventCreatedSuccessfully')
            );
            this._router.navigate(['/masjid-events']);
          }),
          catchError((error) => {
            this._notification.notifyFailure(
              this._translateService.translate('masjid_events.create.error.createEventFailed')
            );
            return of(MasjidEventActions.createEventFailure({ error: error.message }));
          })
        )
      )
    )
  );

  updateEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MasjidEventActions.updateEvent),
      mergeMap(({ id, event }) =>
        this.masjidEventService.updateEvent(id, event).pipe(
          map((updatedEvent) =>
            MasjidEventActions.updateEventSuccess({ event: updatedEvent })
          ),
          tap(() => {
            this._notification.notifySuccess(
              this._translateService.translate('masjid_events.update.eventUpdatedSuccessfully')
            );
            this._router.navigate(['/masjid-events']);
          }),
          catchError((error) => {
            this._notification.notifyFailure(
              this._translateService.translate('masjid_events.update.error.updateEventFailed')
            );
            return of(MasjidEventActions.updateEventFailure({ error: error.message }));
          })
        )
      )
    )
  );

  deleteEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MasjidEventActions.deleteEvent),
      mergeMap(({ id }) =>
        this.masjidEventService.deleteEvent(id).pipe(
          map(() => MasjidEventActions.deleteEventSuccess({ id })),
          tap(() => {
            this._notification.notifySuccess(
              this._translateService.translate('masjid_events.delete.eventDeletedSuccessfully')
            );
          }),
          catchError((error) => {
            this._notification.notifyFailure(
              this._translateService.translate('masjid_events.delete.error.deleteEventFailed')
            );
            return of(MasjidEventActions.deleteEventFailure({ error: error.message }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private masjidEventService: MasjidEventService,
    private _notification: NotificationService,
    private _router: Router,
    private _translateService: TranslocoService
  ) {}
} 