import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Translation, TranslocoLoader, provideTransloco } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, forkJoin, catchError, of } from 'rxjs';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    private translations = new Map<string, Translation>();
    private languages = ['en', 'ar', 'es', 'pt'];

    constructor(private http: HttpClient) {
        // Preload and cache all translations at startup
        forkJoin(
            this.languages.map(lang => 
                this.http.get<Translation>(`/assets/i18n/${lang}.json`).pipe(
                    untilDestroyed(this),
                    catchError(() => of({}))
                )
            )
        ).subscribe(results => {
            this.languages.forEach((lang, index) => {
                this.translations.set(lang, results[index]);
            });
        });
    }

    getTranslation(lang: string): Observable<Translation> {
        // Return cached translation if available
        if (this.translations.has(lang)) {
            return of(this.translations.get(lang) as Translation);
        }
        // Fallback to HTTP request if not cached (shouldn't happen in normal flow)
        return this.http.get<Translation>(`/assets/i18n/${lang}.json`).pipe(
            untilDestroyed(this),
            catchError(() => of({}))
        );
    }
}

export const translationProviders = [
    provideTransloco({
        config: {
            availableLangs: ['en', 'ar', 'es', 'pt'],
            defaultLang: 'en',
            fallbackLang: ['en'],
            reRenderOnLangChange: true,
            prodMode: !isDevMode(),
            missingHandler: {
                useFallbackTranslation: true
            }
        },
        loader: TranslocoHttpLoader,
    }),
];
