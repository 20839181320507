import { provideRouter } from '@angular/router';
import { importProvidersFrom } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { materialProviders } from '../shared/providers/material.providers';
import { SearchMasjidsEffects } from './store/effects/search-masjids.effects';
import { searchMasjidsReducer } from './store/reducers/search-masjids.reducer';
import { initialSearchMasjidState } from './store/states/search-masjids.state';
import { searchMasjidsRoutes } from './search-masjids.routes';
import { GeolocationService } from '../shared/services/geolocation.service';
import { GoogleMapsLoaderService } from '../shared/services/google-maps-loader.service';
import { SearchMasjidsService } from './services/search-masjids.service';

export const searchMasjidsProviders = [
    provideRouter(searchMasjidsRoutes),
    provideState('searchMasjids', searchMasjidsReducer, { initialState: initialSearchMasjidState }),
    provideEffects(SearchMasjidsEffects),
    SearchMasjidsService,
    GeolocationService,
    GoogleMapsLoaderService
]; 