import { Inject, Injectable } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ID, Models } from 'appwrite'
import { Observable, catchError, from, throwError } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ContractData as ContactData } from '../interfaces/contact.data.interface'
import { LogType } from '../interfaces/log.interface'
import { APPWRITE_SDK, AppwriteSdk } from './appwrite.provider'
import { LogService } from './log.service'
import Utils from './utils'

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class ContactService {
    constructor(
        private logger: LogService,
        @Inject(APPWRITE_SDK) private _appwrite: AppwriteSdk
    ) {}

    saveUserContactMessage(data: ContactData): Observable<Models.Document> {
        return from(
            this._appwrite.databases
                .createDocument(
                    environment.main_database_id,
                    environment.masjid_contact_collection_id,
                    ID.unique(),
                    {
                        contact_name: data.name,
                        contact_email: data.email,
                        contact_message: data.message,
                        contact_registered: data.registered,
                        user_id: data.userId || null
                    }
                )
                .then((res) => {
                    return res
                })
        ).pipe(
            catchError((error: any) => {
                this.logger.writeLogAsync({
                    logType: LogType.Error,
                    message: Utils.handleErrorMessage(error),
                })
                return throwError(() => error)
            }),
            untilDestroyed(this)
        )
    }
}
