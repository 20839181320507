import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    Renderer2,
} from '@angular/core'

import { Direction } from '@angular/cdk/bidi'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { CommonModule, DOCUMENT } from '@angular/common'
import {
    MatSlideToggleChange,
    MatSlideToggleModule,
} from '@angular/material/slide-toggle'
import { RouterLink, RouterModule } from '@angular/router'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { languageList } from 'src/app/shared/interfaces/language.interface'
import { APPWRITE_SDK, AppwriteSdk } from '../../services/appwrite.provider'
import { StorageService } from '../../services/storage.service'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatButtonModule } from '@angular/material/button'
import { selectAuthState } from 'src/app/auth/store/selectors/auth.selectors'
import { AuthState } from 'src/app/auth/interfaces'
import { AuthActions } from 'src/app/auth/store/actions/auth.actions'

const LANGUAGE_KEY = 'mi-language'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        MatToolbarModule,
        MatIconModule,
        CommonModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatButtonModule,
        RouterLink,
        RouterModule,
        TranslocoModule
    ],
})
export class HeaderComponent implements OnInit {
    @Output() toggleSideNavEvent = new EventEmitter()
    @Output() direction = new EventEmitter<Direction>()
    authState$: Observable<AuthState> = this._store.select(selectAuthState)
    isMobileMenuOpen = false;

    constructor(
        private breakpointObserver: BreakpointObserver,
        @Inject(DOCUMENT) private document: Document,
        private _translocoService: TranslocoService,
        private _storageService: StorageService,
        private rerender: Renderer2,
        @Inject(APPWRITE_SDK)
        private _appwrite: AppwriteSdk,
        private _store: Store,
    ) {}

    ngOnInit(): void {
        let value = this._storageService.getItem(LANGUAGE_KEY)
        if (value) {
            let langInfo = JSON.parse(value)
            this.languagePicked(langInfo.code)
        }
    }

    toggleSideNav() {
        this.toggleSideNavEvent.emit()
    }

    toggleMobileMenu() {
        this.isMobileMenuOpen = !this.isMobileMenuOpen;
        if (this.isMobileMenuOpen) {
            this.rerender.addClass(this.document.body, 'mobile-menu-open');
        } else {
            this.rerender.removeClass(this.document.body, 'mobile-menu-open');
        }
    }

    logout() {
        this._store.dispatch(AuthActions.logout())
    }

    languages = languageList

    isHandset$: Observable<boolean> = this.breakpointObserver
        .observe(Breakpoints.Handset)
        .pipe(
            map((result) => result.matches),
            shareReplay()
        )

    languagePicked(code: string) {
        if (code == 'ar') this.direction.emit('rtl')
        else this.direction.emit('ltr')

        this._translocoService.setActiveLang(code)
        this._storageService.setItem(
            LANGUAGE_KEY,
            JSON.stringify({ code: code })
        )
        if (code === 'pt') {
            code = 'pt-br'
        }
        this._appwrite.locale.client.setLocale(code)
    }

    switchMode({ checked: isDarkMode }: MatSlideToggleChange) {
        const hostClass = isDarkMode ? 'theme-dark' : 'theme-light'
        const backgroundImage = isDarkMode
            ? 'background-dark-image'
            : 'background-light-image'

        if (isDarkMode) {
            this.rerender.removeClass(
                this.document.body
                    .getElementsByTagName('mat-sidenav-content')
                    .item(0),
                'background-light-image'
            )
            this.rerender.addClass(
                this.document.body
                    .getElementsByTagName('mat-sidenav-content')
                    .item(0),
                'background-dark-image'
            )
        } else {
            this.rerender.removeClass(
                this.document.body
                    .getElementsByTagName('mat-sidenav-content')
                    .item(0),
                'background-dark-image'
            )
            this.rerender.addClass(
                this.document.body
                    .getElementsByTagName('mat-sidenav-content')
                    .item(0),
                'background-light-image'
            )
        }

        this.rerender.setAttribute(this.document.body, 'class', hostClass)
    }
}
