import { Action, createReducer, on } from '@ngrx/store';
import {
    MasjidStateInterface,
    initialMasjidState,
} from 'src/app/masjids/interfaces/masjid.state.interface';
import {
    loadAdminMasjids,
    loadAdminMasjidsFailure,
    loadAdminMasjidsSuccess,
    registerMasjid,
    registerMasjidFailure,
    registerMasjidSuccess,
} from '../actions/masjid.actions';

const masjidReducer = createReducer<MasjidStateInterface>(
    initialMasjidState,
    on(loadAdminMasjids, (state) => ({
        ...state,
        error: null,
        loading: true
    })),
    on(loadAdminMasjidsSuccess, (state, { masjids }) => ({
        ...state,
        userAdminMasjid: masjids,
        error: null,
        loading: false
    })),
    on(loadAdminMasjidsFailure, (state, { error }) => ({
        ...state,
        error: error,
        loading: false
    })),
    on(registerMasjidSuccess, (state, { masjid }) => ({
        ...state,
        masjids: [...state.masjidsList, masjid], // Add new masjid
        error: null,
        loading: false
    })),
    on(registerMasjid, (state) => ({
        ...state,
        error: null,
        loading: false
    })),
    on(registerMasjidFailure, (state, { error }) => ({
        ...state,
        error: error,
        loading: false
    }))
);

export function reducerMasjid(
    state: MasjidStateInterface | undefined,
    action: Action
): MasjidStateInterface {
    return masjidReducer(state, action);
}
