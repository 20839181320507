import { Action, createReducer, on } from '@ngrx/store'
import {
    CreateMasjidStateInterface,
    initialCreateMasjidState,
} from 'src/app/masjids/interfaces/masjid.state.interface'
import {
    registerMasjid,
    registerMasjidSuccess,
    registerMasjidFailure,
} from '../actions/masjid.actions'

const createMasjidReducer = createReducer<CreateMasjidStateInterface>(
    initialCreateMasjidState,
    on(
        registerMasjid,
        (state): CreateMasjidStateInterface => ({
            ...state,
            isSubmittingSave: true,
            backendError: null,
            loading: false
        })
    ),
    on(
        registerMasjidSuccess,
        (state): CreateMasjidStateInterface => ({
            ...state,
            isSubmittingSave: false,
            backendError: null,
            loading: false
        })
    ),
    on(
        registerMasjidFailure,
        (state, { error }): CreateMasjidStateInterface => ({
            ...state,
            isSubmittingSave: false,
            backendError: error,
            loading: false
        })
    )
)

export function reducerCreateMasjid(
    state: CreateMasjidStateInterface | undefined,
    action: Action
): CreateMasjidStateInterface {
    return createMasjidReducer(state, action)
}
