import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap, switchMap, concatMap, EMPTY, take, from } from 'rxjs';
import { AuthService } from '../../../shared/services/auth.service';
import { FileStorageService } from '../../../shared/services/file-storage.service';
import { UserAddressDocumentService } from '../../../shared/services/user-address.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { User, UserAddress } from '../../interfaces/user.interface';
import { AppwriteException } from 'appwrite';
import Utils from '../../../shared/services/utils';
import { SerializableFile } from '../../interfaces/profile.interface';
import { ProfileActions } from '../actions/profile.actions';
import { AuthActions } from '../actions/auth.actions';

export const DefaultAvatarPath = 'assets/images/profile-avatar.svg';

@Injectable()
export class ProfileEffects {
  updateName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.updateName),
      concatMap(({ firstName, lastName }) =>
        this.authService.updateUserName(firstName, lastName).pipe(
          map((updatedUser) => {
            this.store.dispatch(AuthActions.refreshCurrentUser({ user: updatedUser }));
            return ProfileActions.updateNameSuccess();
          }),
          tap(() => this.notificationService.notifySuccess(
            this.transloco.translate('userInfo.profileSuccessfullyUpdated')
          )),
          catchError((error) => {
            console.error('Failed to update profile:', error);
            return of(ProfileActions.updateNameFailure({ 
              error: this.transloco.translate('userInfo.failedToUpdateProfile') 
            }));
          })
        )
      )
    )
  );

  updatePhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.updatePhone),
      concatMap(({ phone, password }) =>
        this.authService.updateUserPhone(phone, password).pipe(
          switchMap(user => {
            this.store.dispatch(AuthActions.refreshCurrentUser({ user: user }));
            return this.authService.isAuthenticated().pipe(
              take(1),
              map(authState => {
                if (authState.currentUser) {
                  this.store.dispatch(AuthActions.refreshCurrentUser({ user: authState.currentUser }));
                }
                return ProfileActions.updatePhoneSuccess();
              })
            );
          }),
          tap(() => this.notificationService.notifySuccess(
            this.transloco.translate('userInfo.phoneSuccessfullyUpdated')
          )),
          catchError((error) => {
            console.error('Failed to update phone:', error);
            const errorMessage = this.transloco.translate('userInfo.failedToUpdatePhone');
            this.notificationService.notifyFailure(errorMessage);
            return of(ProfileActions.updatePhoneFailure({ error: errorMessage }));
          })
        )
      )
    )
  );

  updateAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.updateAddress),
      concatMap(({ userID, address }) =>
        this.userAddressService.findUserAddressDocument(userID).pipe(
          switchMap(existingAddress => {
            if (existingAddress) {
              return this.userAddressService.updateUserAddressDocument(userID, address);
            } else {
              return this.userAddressService.createUserAddressDocument(userID, address);
            }
          }),
          switchMap(() => 
            this.authService.isAuthenticated().pipe(
              take(1),
              switchMap(authState => {
                if (authState.currentUser) {
                  return this.authService.updateUserPreferences(
                    authState.currentUser.$id,
                    true, // hasAddress
                    authState.currentUser?.avatarId || null,
                    authState.currentUser?.isAdmin || false
                  ).pipe(
                    tap(prefs => {
                      this.store.dispatch(AuthActions.refreshCurrentUser({ user: prefs }));
                    }),
                    map(() => ProfileActions.updateAddressSuccess())
                  );
                }
                return of(ProfileActions.updateAddressSuccess());
              })
            )
          ),
          tap(() => this.notificationService.notifySuccess(
            this.transloco.translate('userAddress.addressSuccessfullyUpdated')
          )),
          catchError((error: AppwriteException | Error) => {
            console.error('Failed to update address:', error);
            return of(ProfileActions.updateAddressFailure({ 
              error: this.transloco.translate('userAddress.failedToUpdateAddress') 
            }));
          })
        )
      )
    )
  );

  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.updatePassword),
      concatMap(({ currentPassword, newPassword }) =>
        this.authService.changePassword(currentPassword, newPassword).pipe(
          map(() => ProfileActions.updatePasswordSuccess()),
          tap(() => {
            this.notificationService.notifySuccess(
              this.transloco.translate('security.passwordSuccessfullyUpdated')
            );
            // Add a small delay before logout to allow the notification to be seen
            setTimeout(() => {
              this.store.dispatch(AuthActions.logout());
            }, 2000);
          }),
          catchError((error) => {
            console.error('Failed to update password:', error);
            let errorMsg = this.transloco.translate('security.failedToUpdatePassword');
            
            if (error.message?.includes('Invalid credentials')) {
              errorMsg = this.transloco.translate('security.invalidCurrentPassword');
            }
            
            this.notificationService.notifyFailure(errorMsg);
            return of(ProfileActions.updatePasswordFailure({ error: errorMsg }));
          })
        )
      )
    )
  );

  uploadAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.uploadAvatar),
      concatMap(({ user, userID, base64Data, fileName, fileType }) => {
        // Generate a new avatar ID for the new upload
        const newAvatarId = this.fileService.generateAvatarImageID(userID);
        
        // Create a pipeline that attempts to delete the old avatar if it exists
        const deleteOldAvatar$ = user?.avatarId 
          ? this.fileService.deleteAvatarImg(user.avatarId).pipe(
              catchError((error: AppwriteException) => {
                // If file not found (404), just log and continue
                if (error.code === 404 && error.type === 'file_not_found') {
                  console.log('Old avatar not found or already deleted:', Utils.formatAppWriteException(error));
                }
                // For any error during delete, just log and continue with upload
                return of(null);
              })
            )
          : of(null);

        // Convert base64 to Blob
        const base64WithoutPrefix = base64Data.split(',')[1];
        const byteCharacters = atob(base64WithoutPrefix);
        const byteNumbers = new Array(byteCharacters.length);
        
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: fileType });
        const reconstructedFile = new File([blob], fileName, { type: fileType });

        return deleteOldAvatar$.pipe(
          switchMap(() => this.fileService.CreateAvatarImg(reconstructedFile, newAvatarId)),
          switchMap(() => {
            const updatedUser = {
              ...user,
              avatarId: newAvatarId,
            } as User;
            return this.authService.updateUser(userID, updatedUser).pipe(
              map(updatedUser => {
                const avatarUrl = this.fileService.getAvatarUrl(newAvatarId);
                return { updatedUser, avatarUrl };
              })
            );
          }),
          tap(({ updatedUser }) => {
            // Dispatch user update as a side effect
            this.store.dispatch(AuthActions.refreshUser({ user: updatedUser }));
          }),
          map(({ avatarUrl }) => ProfileActions.uploadAvatarSuccess({ avatarUrl })),
          tap(() => this.notificationService.notifySuccess(
            this.transloco.translate('userInfo.avatarSuccessfullyUpdated')
          )),
          catchError((error) => {
            const errorMessage = error instanceof AppwriteException ? 
              Utils.formatAppWriteException(error) : 
              Utils.handleErrorMessage(error);
            console.error('Failed to complete avatar upload process:', errorMessage);
            this.notificationService.notifyFailure(
              this.transloco.translate('userInfo.failedToUploadAvatar')
            );
            return of(ProfileActions.uploadAvatarFailure({ 
              error: this.transloco.translate('userInfo.failedToUploadAvatar') 
            }));
          })
        );
      })
    )
  );

  deleteAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.deleteAvatar),
      concatMap(({ user }) => {
        if (!user.avatarId) return of(ProfileActions.deleteAvatarSuccess());
        
        return this.fileService.deleteAvatarImg(user.avatarId).pipe(
          catchError((error: AppwriteException) => {
            // Handle AppwriteException for file not found (404)
            if (error.code === 404 && error.type === 'file_not_found') {
              console.log('Avatar file not found on server, continuing with user update');
              return of(null);
            }
            // For other errors, log and propagate them
            console.error('Error deleting avatar:', Utils.formatAppWriteException(error));
            throw error;
          }),
          switchMap(() => this.authService.isAuthenticated()),
          switchMap((authState) => {
            const updatedUser = { ...user, avatarId: null } as User;
            return this.authService.updateUser(authState.currentUser!.$id, updatedUser).pipe(
              tap(updatedUser => {
                // Dispatch user update as a side effect
                this.store.dispatch(AuthActions.refreshUser({ user: updatedUser }));
              }),
              map(() => ProfileActions.deleteAvatarSuccess())
            );
          }),
          tap(() => this.notificationService.notifySuccess(
            this.transloco.translate('userInfo.avatarSuccessfullyDeleted')
          )),
          catchError((error: AppwriteException) => {
            const errorMessage = Utils.formatAppWriteException(error);
            console.error('Failed to delete avatar:', errorMessage);
            this.notificationService.notifyFailure(
              this.transloco.translate('userInfo.failedToDeleteAvatar')
            );
            return of(ProfileActions.deleteAvatarFailure({ 
              error: this.transloco.translate('userInfo.failedToDeleteAvatar') 
            }));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private fileService: FileStorageService,
    private userAddressService: UserAddressDocumentService,
    private notificationService: NotificationService,
    private transloco: TranslocoService,
    private store: Store
  ) {}
} 