<div class="home-container" *transloco="let t">
    <!-- Hero Section -->
    <section class="hero-section">
        <h2>{{ t('home.welcome') }}</h2>
        <p>{{ t('home.discover') }}</p>
    </section>

    <!-- Featured Masjids Section -->
    <section class="featured-masjids-section">
        <h2>{{ t('home.featuredMasjids') }}</h2>
        <div class="masjids-grid">
            <ng-container *ngIf="featuredMasjids$ | async as masjids">
                <ng-container *ngIf="hasMasjids$ | async; else noMasjids">
                    <mat-card class="masjid-card" *ngFor="let masjid of masjids">
                        <img mat-card-image [src]="getMasjidImageSrc(masjid.ImagesIds[0])" [alt]="masjid.name" *ngIf="masjid.ImagesIds && masjid.ImagesIds.length > 0">
                        <mat-card-content>
                            <h3>{{masjid.name}}</h3>
                            <p>{{masjid.description | slice:0:150}}...</p>
                        </mat-card-content>
                        <mat-card-actions>
                            <button mat-button color="primary" [routerLink]="['/masjid/details', masjid.id]">
                                View Details
                            </button>
                        </mat-card-actions>
                    </mat-card>
                </ng-container>
            </ng-container>
        </div>
        <ng-template #noMasjids>
            <div class="no-masjids-message">
                <mat-card>
                    <mat-card-content>
                        <p>{{ t('home.noMasjidsAvailable') }}</p>
                    </mat-card-content>
                </mat-card>
            </div>
        </ng-template>
        <div class="more-masjids">
            <button mat-raised-button color="primary" routerLink="/masjid/list">
                {{ t('home.viewAllMasjids') }}
            </button>
        </div>
    </section>

    <!-- Search Masjids Section -->
    <section class="search-masjids-section">
        <h2>{{ t('home.searchMasjids') }}</h2>
        <div class="search-container">
            <app-search-box></app-search-box>
        </div>
    </section>

    <!-- Latest News Section -->
    <section class="news-section">
        <h2>{{ t('home.latestNews') }}</h2>
        <div class="news-grid">
            <ng-container *ngIf="latestNews$ | async as news">
                <ng-container *ngIf="hasNews$ | async; else noNews">
                    <mat-card class="news-card" *ngFor="let item of news.slice(0,3)">
                        <img mat-card-image [src]="item.imageUrl" [alt]="item.title">
                        <mat-card-content>
                            <h3>{{item.title}}</h3>
                            <p>{{item.description | slice:0:150}}...</p>
                        </mat-card-content>
                        <mat-card-actions>
                            <button mat-button color="primary" [routerLink]="['/news/details', item.news_id]">Read more</button>
                        </mat-card-actions>
                    </mat-card>
                </ng-container>
            </ng-container>
        </div>
        <ng-template #noNews>
            <div class="no-news-message">
                <mat-card>
                    <mat-card-content>
                        <p>{{ t('home.noNewsAvailable') }}</p>
                    </mat-card-content>
                </mat-card>
            </div>
        </ng-template>
        <div class="more-news">
            <button mat-raised-button color="primary" routerLink="/news/list">
                {{ t('home.moreNews') }}
            </button>
        </div>
    </section>

    <!-- Latest Events Section -->
    <section class="events-section">
        <h2>{{ t('home.latestEvents') }}</h2>
        <div class="events-grid">
            <ng-container *ngIf="upcomingEvents$ | async as events">
                <ng-container *ngIf="hasEvents$ | async; else noEvents">
                    <mat-card class="event-card" *ngFor="let event of events">
                        <img mat-card-image [src]="getEventImageSrc(event.imageId)" [alt]="event.title" *ngIf="event.imageId">
                        <mat-card-content>
                            <h3>{{event.title}}</h3>
                            <p>{{event.description | slice:0:150}}...</p>
                            <div class="event-details">
                                <p class="event-time">
                                    <span>{{ t('masjid_events.list.start_time') }}</span>
                                    {{event.startTime | date:'short'}}
                                </p>
                                <p class="event-time">
                                    <span>{{ t('masjid_events.list.end_time') }}</span>
                                    {{event.endTime | date:'short'}}
                                </p>
                                <p class="event-status completed" *ngIf="event.isCompleted">
                                    {{ t('masjid_events.list.completed') }}
                                </p>
                                <p class="event-status upcoming" *ngIf="!event.isCompleted">
                                    {{ t('masjid_events.list.upcoming') }}
                                </p>
                            </div>
                        </mat-card-content>
                        <mat-card-actions>
                            <button mat-button color="primary" [routerLink]="['/masjid-events/details', event.id]">
                                {{ t('home.readMore') }}
                            </button>
                        </mat-card-actions>
                    </mat-card>
                </ng-container>
            </ng-container>
        </div>
        <ng-template #noEvents>
            <div class="no-events-message">
                <mat-card>
                    <mat-card-content>
                        <p>{{ t('home.noEventsAvailable') }}</p>
                    </mat-card-content>
                </mat-card>
            </div>
        </ng-template>
        <div class="more-events">
            <button mat-raised-button color="primary" routerLink="/masjid-events/list">
                {{ t('home.moreEvents') }}
            </button>
        </div>
    </section>

    <!-- Contact Section -->
    <section class="contact-section">
        <h2>{{ t('home.contactUs') }}</h2>
        <app-contact></app-contact>
    </section>
</div>
