import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as SearchMasjidsActions from '../actions/search-masjids.actions';
import { SearchMasjidsService } from '../../services/search-masjids.service';

@Injectable()
export class SearchMasjidsEffects {
    searchMasjids$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SearchMasjidsActions.searchMasjids),
            mergeMap(({ request }) =>
                this.searchMasjidsService.searchMasjids(request).pipe(
                    map(response => SearchMasjidsActions.searchMasjidsSuccess({ response })),
                    catchError(error => of(SearchMasjidsActions.searchMasjidsFailure({ error: error.message })))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private searchMasjidsService: SearchMasjidsService
    ) {}
} 