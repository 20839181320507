import { createReducer, on } from '@ngrx/store';
import { initialSearchMasjidState } from '../states/search-masjids.state';
import * as SearchMasjidsActions from '../actions/search-masjids.actions';

export const searchMasjidsReducer = createReducer(
    initialSearchMasjidState,
    on(SearchMasjidsActions.searchMasjids, (state) => ({
        ...state,
        isLoading: true,
        error: null
    })),
    on(SearchMasjidsActions.searchMasjidsSuccess, (state, { response }) => ({
        ...state,
        searchResults: response.results,
        isLoading: false,
        error: null
    })),
    on(SearchMasjidsActions.searchMasjidsFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error
    })),
    on(SearchMasjidsActions.updateFilters, (state, { filters }) => ({
        ...state,
        filters
    })),
    on(SearchMasjidsActions.clearSearch, () => initialSearchMasjidState)
); 