import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppwriteException } from 'appwrite';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { NewsInterface } from '../../interfaces/news.interface';
import { NewsService } from 'src/app/shared/services/news.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TranslocoService } from '@ngneat/transloco';
import {
    NewsActions,
    loadMasjidsNews,
    loadMasjidsNewsFailure,
    loadMasjidsNewsSuccess,
    viewNewsDetailsAndNavigate,
} from '../actions/news.actions';
import { NewsStore } from '../states/news.state';

@Injectable()
export class NewsEffects {
    constructor(
        private actions$: Actions,
        private _router: Router,
        private _newsService: NewsService,
        private _notificationService: NotificationService,
        private _newsStore: NewsStore,
        private _transloco: TranslocoService
    ) {}

    viewNewsDetailsAndNavigate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(viewNewsDetailsAndNavigate),
            tap((action) => {
                console.log(
                    'starting effect viewNewsDetailsAndNavigate$ with news ID',
                    action.news_id
                );
                this._router.navigate(['news', 'details', action.news_id]);
            })
        ),
        { dispatch: false }
    );

    loadNewsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadMasjidsNews),
            tap(() => console.log('11111')),
            switchMap(() => {
                this._notificationService.loading(this._transloco.translate('news.manage.loading.news'));
                return this._newsService.getLatestNews().pipe(
                    map((news) => loadMasjidsNewsSuccess({ newsList: news })),
                    catchError((error) => {
                        const actions = [
                            loadMasjidsNewsFailure({ error }),
                            NewsActions.handleAPIError({ error }),
                            NewsActions.showErrorNotification({
                                message: 'Error loading news list',
                            }),
                        ];
                        return of(...actions);
                    })
                );
            })
        )
    );

    loadMasjidNewsListSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadMasjidsNewsSuccess),
            tap((action) => {
                console.log('test 123123');
                this._newsStore.setNewsList(action.newsList);
                this._notificationService.removeLoading();
            })
        ),
        { dispatch: false }
    );
} 