import { createReducer, on } from '@ngrx/store';
import { MasjidEventActions } from './actions/masjid-event.actions';
import { initialMasjidEventState } from './states/masjid-event.state';
import { initialEditMasjidEventState } from './states/edit-masjid-event.state';

export const masjidEventReducer = createReducer(
  initialMasjidEventState,
  on(MasjidEventActions.loadEvents, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(MasjidEventActions.loadEventsSuccess, (state, { events }) => ({
    ...state,
    events,
    loading: false,
  })),
  on(MasjidEventActions.loadEventsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(MasjidEventActions.loadEvent, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(MasjidEventActions.loadEventSuccess, (state, { event }) => ({
    ...state,
    selectedEvent: event,
    loading: false,
  })),
  on(MasjidEventActions.loadEventFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(MasjidEventActions.clearSelectedEvent, (state) => ({
    ...state,
    selectedEvent: null,
  }))
);

export const editMasjidEventReducer = createReducer(
  initialEditMasjidEventState,
  on(MasjidEventActions.loadEvent, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(MasjidEventActions.loadEventSuccess, (state, { event }) => ({
    ...state,
    event,
    loading: false,
  })),
  on(MasjidEventActions.loadEventFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(MasjidEventActions.updateEvent, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(MasjidEventActions.updateEventSuccess, (state, { event }) => ({
    ...state,
    event,
    loading: false,
  })),
  on(MasjidEventActions.updateEventFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
); 