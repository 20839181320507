import { createReducer, on } from '@ngrx/store';
import { ProfileState, initialProfileState } from '../../interfaces/state.interface';
import { ProfileActions } from '../actions/profile.actions';

export const profileReducer = createReducer(
  initialProfileState,
  // Name update
  on(ProfileActions.updateName, (state) => ({
    ...state,
    isSubmittingSave: true,
    errorMessage: null
  })),
  on(ProfileActions.updateNameSuccess, (state) => ({
    ...state,
    isSubmittingSave: false
  })),
  on(ProfileActions.updateNameFailure, (state, { error }) => ({
    ...state,
    isSubmittingSave: false,
    errorMessage: error
  })),

  // Phone update
  on(ProfileActions.updatePhone, (state) => ({
    ...state,
    isSubmittingSave: true,
    errorMessage: null
  })),
  on(ProfileActions.updatePhoneSuccess, (state) => ({
    ...state,
    isSubmittingSave: false
  })),
  on(ProfileActions.updatePhoneFailure, (state, { error }) => ({
    ...state,
    isSubmittingSave: false,
    errorMessage: error
  })),

  // Address update
  on(ProfileActions.updateAddress, (state) => ({
    ...state,
    isSubmittingAddressSave: true,
    errorMessage: null
  })),
  on(ProfileActions.updateAddressSuccess, (state) => ({
    ...state,
    isSubmittingAddressSave: false
  })),
  on(ProfileActions.updateAddressFailure, (state, { error }) => ({
    ...state,
    isSubmittingAddressSave: false,
    errorMessage: error
  })),

  // Password update
  on(ProfileActions.updatePassword, (state) => ({
    ...state,
    isSubmittingPasswordSave: true,
    errorMessage: null
  })),
  on(ProfileActions.updatePasswordSuccess, (state) => ({
    ...state,
    isSubmittingPasswordSave: false
  })),
  on(ProfileActions.updatePasswordFailure, (state, { error }) => ({
    ...state,
    isSubmittingPasswordSave: false,
    errorMessage: error
  })),

  // Avatar upload
  on(ProfileActions.uploadAvatar, (state) => ({
    ...state,
    isSubmittingUpload: true,
    errorMessage: null
  })),
  on(ProfileActions.uploadAvatarSuccess, (state, { avatarUrl }) => ({
    ...state,
    isSubmittingUpload: false,
    avatarInfo: {
      ...state.avatarInfo,
      avatarUrl,
      hasAvatar: true
    },
    showUploadButton: false
  })),
  on(ProfileActions.uploadAvatarFailure, (state, { error }) => ({
    ...state,
    isSubmittingUpload: false,
    errorMessage: error
  })),

  // Avatar delete
  on(ProfileActions.deleteAvatar, (state) => ({
    ...state,
    isSubmittingDelete: true,
    errorMessage: null
  })),
  on(ProfileActions.deleteAvatarSuccess, (state) => ({
    ...state,
    isSubmittingDelete: false,
    avatarInfo: {
      avatarUrl: 'assets/images/profile-avatar.svg',
      hasAvatar: false
    }
  })),
  on(ProfileActions.deleteAvatarFailure, (state, { error }) => ({
    ...state,
    isSubmittingDelete: false,
    errorMessage: error
  })),

  // UI actions
  on(ProfileActions.setShowUploadButton, (state, { show }) => ({
    ...state,
    showUploadButton: show
  })),
); 