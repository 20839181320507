import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { createEffect, ofType, Actions } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AppwriteException } from 'appwrite'
import {
    map,
    concatMap,
    switchMap,
    of,
    tap,
    catchError,
    exhaustMap,
    finalize,
} from 'rxjs'
import { AuthService } from 'src/app/shared/services/auth.service'
import { UserAddressDocumentService } from 'src/app/shared/services/user-address.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { AuthActions } from '../actions/auth.actions'
import { UserPreferences } from '../../interfaces'
import { TranslocoService } from '@ngneat/transloco'

@Injectable()
export class AuthEffects {
    login$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AuthActions.login),
            tap(() => this._notification.loading()),
            switchMap(({ email, password, returnUrl }) =>
                this._authService.login({ email, password, returnUrl }).pipe(
                    map((auth) => {
                        this._notification.removeLoading()
                        this._notification.notifySuccess(this._transloco.translate('login.success'))
                        // Store auth state in localStorage
                        localStorage.setItem('auth', JSON.stringify(auth))
                        // Let loginSuccess handle navigation
                        return AuthActions.loginSuccess({ auth, returnUrl, isLoggedIn: true })
                    }),
                    catchError((error: Error | AppwriteException) => {
                        this._notification.removeLoading()
                        console.log('login error:', error)
                        return of(AuthActions.loginFailure({ error: error.message }))
                    })
                )
            )
        )
    )

    redirectAfterLoginSuccessful$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AuthActions.loginSuccess),
                tap((action) => {
                    // Only navigate if this is from a login action, not an auth check
                    if (action.isLoggedIn) {
                        localStorage.setItem('auth', JSON.stringify(action.auth))
                        const url = action.returnUrl || '/'
                        console.log('Redirecting to:', url)
                        this._router.navigateByUrl(url)
                    }
                }),
                map((action) => action.auth),
                concatMap((auth) => {
                    return this._userAddressService
                        .findUserAddressDocument(auth.currentUser?.$id!)
                        .pipe(
                            switchMap((doc) => {
                                if (auth.currentUser) {
                                    const hasAddressValue = !!doc
                                    if (auth.currentUser.hasAddress !== hasAddressValue) {
                                        return this._authService.updateUserPreferences(auth.currentUser!.$id, hasAddressValue, auth.currentUser!.avatarId, auth.currentUser!.isAdmin)
                                    }
                                }
                                return of(auth.currentUser || {
                                    hasAddress: !!doc,
                                    avatarId: null,
                                    isAdmin: false,
                                } as UserPreferences)
                            })
                        )
                }),
                tap((prefs) => {
                    console.log('checked address document, preferences:', prefs)
                }),
                catchError((err) => {
                    console.error('After login redirect error: ', err)
                    return of(err)
                })
            ),
        { dispatch: false }
    )
    checkAuth$ = createEffect(() =>
        this._actions$.pipe(
            ofType(AuthActions.checkAuth),
            exhaustMap(() =>
                this._authService.isAuthenticated().pipe(
                    switchMap((authState) => {
                        // First check if we have a valid current session
                        if (
                            authState.isAuthenticated &&
                            authState.currentUser
                        ) {
                            // Don't dispatch loginSuccess here as it triggers redirects
                            return of(AuthActions.authSuccess({ authState }))
                        }

                        // If no current session, check stored auth
                        const storedAuth = localStorage.getItem('auth')
                        if (storedAuth) {
                            const parsedAuth = JSON.parse(storedAuth)
                            if (parsedAuth.isAuthenticated) {
                                return this._authService.isAuthenticated().pipe(
                                    map((newAuthState) => {
                                        if (newAuthState.currentUser) {
                                            // Don't dispatch loginSuccess here either
                                            return AuthActions.authSuccess({
                                                authState: newAuthState,
                                            })
                                        }
                                        localStorage.removeItem('auth')
                                        return AuthActions.authFailure()
                                    })
                                )
                            }
                        }

                        // Just return auth failure and let guards handle redirects
                        return of(AuthActions.authFailure())
                    }),
                    catchError((error: AppwriteException) => {
                        if (
                            error.code === 401 &&
                            error.type === 'user_session_already_exists'
                        ) {
                            return this._authService.isAuthenticated().pipe(
                                map((authState) => {
                                    if (authState.currentUser) {
                                        // Don't dispatch loginSuccess here either
                                        return AuthActions.authSuccess({ authState })
                                    }
                                    return AuthActions.authFailure()
                                })
                            )
                        }
                        return of(AuthActions.authFailure())
                    })
                )
            )
        )
    )

    authSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AuthActions.authSuccess),
                tap(({ authState }) => {
                    console.log('Auth success:', authState)
                    if (authState.isAuthenticated) {
                        localStorage.setItem('auth', JSON.stringify(authState))
                        // Update auth state without triggering navigation
                        this._store.dispatch(
                            AuthActions.refreshAuthState({
                                auth: authState
                            })
                        )
                    }
                })
            ),
        { dispatch: false }
    )

    authFailure$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AuthActions.authFailure),
                tap(() => {
                    console.log('Auth failed')
                    localStorage.removeItem('auth')

                    // Let the AuthenticatedGuard handle protected route redirects
                    // Don't redirect here
                })
            ),
        { dispatch: false }
    )

    logout$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AuthActions.logout),
                tap(() => {
                    localStorage.removeItem('auth')
                    // Dispatch auth failure to ensure store is cleaned up
                    this._store.dispatch(AuthActions.authFailure())
                    // Navigate to home after a delay
                    setTimeout(() => {
                        console.log('Logging out, navigating to home')
                        this._router.navigate(['/home'])
                    }, 3000)
                })
            ),
        { dispatch: false }
    )

    logoutUserSuccessful$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AuthActions.logout),
                tap(() => this._notification.loading()),
                concatMap(() =>
                    this._authService.logout().pipe(
                        tap(() => {
                            this._notification.removeLoading()
                            this._notification.notifySuccess(
                                this._transloco.translate('auth.logout.success')
                            )
                        }),
                        catchError((err) => {
                            console.error('User logout error:', err)
                            this._notification.removeLoading()
                            this._notification.notifyFailure(err.message)
                            return of(err)
                        })
                    )
                )
            ),
        { dispatch: false }
    )

    redirectAfterRecoverPasswordSuccessfully$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AuthActions.recoverSuccess),
                tap((request) => {
                    this._router.navigateByUrl('/auth/login')
                }),
                catchError((err) => {
                    console.log('after recover redirect error: ', err)
                    return of(err)
                })
            ),
        { dispatch: false }
    )

    redirectAfterRegisterSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(AuthActions.registerSuccess),
                tap(() => {
                    // Redirect to main app after successful registration
                    this._router.navigateByUrl('/')
                }),
                catchError((err) => {
                    console.log('register effect redirect error: ', err)
                    this._notification.notifyFailure(
                        this._transloco.translate('auth.register.redirectFailed')
                    )
                    return of(err)
                })
            ),
        { dispatch: false }
    )

    constructor(
        private _actions$: Actions,
        private _router: Router,
        private _userAddressService: UserAddressDocumentService,
        private _store: Store,
        private _notification: NotificationService,
        private _authService: AuthService,
        private _transloco: TranslocoService
    ) {}
}
